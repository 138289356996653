$active: #3feabe;
$inactive: #f5245e;
$almost-inactive: #fdd482;
$blue: #5f8bfa;
$blue-active: #0042eb;
$white-blue: #edf2ff;
$white: #ffffff;
$menu_width: 95px;
$menu_expand_width: 170px;
$header_height: 120px;
$modal: $white;
$background-modal: rgba(0, 0, 0, 0.5);
$boxShadow: #03ebfc33;

$bacground: $white-blue;
