@import "../styles/Variables.styles.scss";

table,
thead,
tbody,
tfoot,
tr,
th,
td {
  //  display: block;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  border: none;
  border-collapse: inherit;
  border-spacing: 0;
  border-color: inherit;
  vertical-align: inherit;
  text-align: center;
  font-weight: inherit;
}

table {
  background: $white;

  thead {
    background: $white-blue;
    // position: sticky;
    z-index: 1;
    th {
      height: 50px;
      vertical-align: middle;
      font-weight: bold;
      word-wrap: break-word;
      max-width: 100px;
      &:first-child {
        border-radius: 4px 0 0 4px;
      }
      &:last-child {
        border-radius: 0 4px 4px 0;
      }
      flex: 1;
      padding: 0 1%;
    }
  }
  tbody {
    tr {
      height: 50px;
      vertical-align: middle;
      box-sizing: border-box;
      // cursor: pointer;
      &:hover {
        background-color: #edf2ff50;
      }
      td {
        border-bottom: 1px solid #dde5f9;
        padding: 0 1%;
        word-wrap: break-word;
        max-width: 100px;
      }
    }
  }
}
