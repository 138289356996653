@mixin attr-x($attr, $attr-count: 10, $attr-steps: 10, $unit: '%', $name: "exemple") {
  $attr-list: null;
  @for $i from 1 through $attr-count {
      $attr-value: $attr-steps * $i;

      .#{$name}#{$attr-value} {
          #{$attr}: #{$attr-value}#{$unit};
      }

      $attr-list: append($attr-list, unquote(".#{$attr}-#{$attr-value}"), comma);
  }

  #{$attr-list} {
  }
}

@include attr-x('font-size', 30, 2, 'px', 'fs');
@include attr-x('font-weight', 10, 100, '', 'fw');

@media (min-width: 2500px) {
  .fs24 {
    font-size: 48px
  }
}

.b-modal {
  background-color: $modal
}

.b-background-modal {
  background-color: $background-modal;
}

.b-active {
  background-color: $active;
}

.b-inactive {
  background-color: $inactive;
}

.b-almost-inactive {
  background-color: $almost-inactive;
}

.primary {
  color: $blue;
}

.primary-hover {
  &:hover {
    color: $blue-active
  }
}

.bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

.lh-1-5 {
  line-height: 1.5;
}