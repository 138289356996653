@import "../styles/Variables.styles.scss";

.modal-right {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 1000;
}

.modal-right-content {
  width: 500px;
  height: 100%;
  background-color: $white;
}

.modal-right-header,
.modal-right-footer,
.modal-right-body {
  padding: 1rem;
}

.modal-right-title {
  text-align: center;
}

.modal-right-footer {
  display: flex;
  justify-content: center;
}

.modal-right-footer-container {
  position: fixed;
  bottom: 1.5rem;
}

.modal-right-body {
  max-height: 75%;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.overflow-y-scroll {
  overflow-y: scroll;
}
