@import "../styles/Variables.styles.scss";

.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  width: 500px;
  background-color: $white;
}

.modal-header,
.modal-footer,
.modal-body {
  padding: 1rem;
}
