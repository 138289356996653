@import "../styles/Variables.styles.scss";

nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  gap: 2rem;
  height: calc(100vh - $header_height - 1rem);
  overflow-y: auto;
  position: sticky;
  top: calc($header_height + 1rem);
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  .logo-container {
    background-color: $blue;
    width: 46px;
    display: flex;
    justify-content: center;
    padding: 10px 0px;
    border-radius: 10px;
    &:hover {
      background-color: $blue-active;
    }
  }
  .link-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  a {
    text-decoration: none;
    font-weight: bold;
    color: black;
    text-transform: uppercase;
    &:hover {
      color: $blue-active;
      .logo-container {
        background-color: $blue-active;
      }
    }
  }
  .textClosed {
    visibility: hidden;
  }
}
.bottom-menu {
  // position: absolute;
  // bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  margin-bottom: 1rem;
}

@media (min-width: 2500px) {
  nav {
    height: calc(100vh - 250px - 1rem);
    .logo-container {
      width: 92px
    }
  }
}