@import "../styles/Variables.styles.scss";

@media (min-width: 2500px) {
  body {
    font-size: 2rem;
  }
  input {
    font-size: 1.5rem;
  }
  select {
    font-size: 1.5rem;
  }
  textarea {
    font-size: 1.5rem;
  }
}

@media (max-width: 1279px) {
  body {
    transform: scale(0.95);
    transform-origin: center;
    font-size: 0.9rem
  };
}

.page {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  box-sizing: border-box;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  .page-column {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    .left-header {
      // flex-shrink: 0;
      height: $header_height;
      display: flex;
      justify-content: center;
      align-items: center;
      position: sticky;
      top: 0;
      button {
        background-color: transparent;
        border: 2px solid $blue;
        border-radius: 50%;
        padding: 15px 10px 12px 10px;
      }
    }
    .side-menu {
      // flex-shrink: 0;
      // flex-grow: 1;
      // overflow-y: auto; Commented in order to make the sticky position works
      // overflow-x: auto; Commented in order to make the sticky position works
      width: $menu_expand_width;
      // margin-top: 3rem;
      position: relative;
      height: 100%;
      &.closed {
        width: $menu_width;
      }
    }
    .right-header {
      // flex-shrink: 0;
      display: flex;
      // width: calc(100vw - $menu_expand_width);
      position: sticky;
      top: 0;
      background-color: white;
    }
    .page_content {
      // flex-shrink: 0;
      // flex-grow: 1;
      // overflow-y: auto;
      // width: calc(100vw - $menu_expand_width);
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

.content {
  width: calc(100vw - $menu_width);
}

.content-full {
  width: calc(100vw - $menu_expand_width);
}

@media (min-width: 2500px) {
  .page {
    .page-column {
      .side-menu {
        width: 250px;
      }
      .left-header {
        height: 250px;
      }
    }
  }
  .content-full {
    width: calc(100vw - 250px);
  }
}

// DASHBOARD
.title-dashboard {
  margin: 3.5rem auto;
  text-align: center;
}
.dashboard {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin-left: 10rem;
  margin-right: 10rem;
}

.dashboard_flat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.display-menu {
  max-width: 85vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  .sortBy {
    width: 180px;
  }
  img {
    border: 2px solid $white-blue;
    border-radius: 4px;
    padding: 7.5px;
    width: 17px;
    height: 17px;
    &.active {
      border-color: $blue-active;
    }
  }
}

.display-menu-right {
  display: flex;
  align-items: center;
  gap: 1rem;
  .display-grid-list {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}

.pagination-container {
  justify-content: center;
}

// LOGIN
.login {
  display: grid;
  grid-template-columns: 60% 40%;
  grid-template-rows: 100vh;
  .login-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    .login-logo {
      margin-top: 10rem;
    }
    .input-width {
      width: 100%;
    }
    .login-link {
      position: absolute;
      bottom: 3.5rem;
    }
    .input-container {
      display: flex;
      flex-direction: column;
      width: 55%;
    }
  }
  .data-section {
    background-image: url(../../public/assets/background.svg);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    .card {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      font-size: xx-large;
      color: $white;
      border: solid 2px $white;
      border-radius: 20px;
      background-color: $blue;
      padding: 3rem 1.5rem;
      height: 185px;
      width: 185px;
      text-align: center;
      img {
        margin-bottom: 2rem;
      }
    }
  }
}

// REGISTER
.register-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  // .login-logo {
  //   margin-top: 3rem;
  // }
  .input-width {
    width: 100%;
  }
  .input-container {
    display: flex;
    flex-direction: column;
    width: 55%;
  }
}
// SCANISETTES
.table-scanisettes {
  width: 85vw;
  margin: 0 auto;
  td {
    .span-status {
      font-weight: bold;
      display: inline-block;
      border-radius: 4px;
      padding: 4px 8px 2px 8px;
      &.active {
        background-color: $active;
      }
      &.inactive {
        background-color: $inactive;
      }
      &.almost-inactive {
        background-color: $almost-inactive;
      }
    }
    &.id {
      color: $blue-active;
    }
  }
}

// SCANISETTE
.scanisette {
  display: flex;
  flex-direction: row;
  gap: 3rem;
  justify-content: center;
  hr {
    border: 2px solid $white-blue;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

.go-back {
  margin-right: 1.5rem;
  cursor: pointer;
}

.input-scanisette-name,
.input-scanisette-location {
  width: 220px;
  height: 1.5rem;
}

@media (min-width: 2500px) {
  .input-scanisette-name,
  .input-scanisette-location {
    width: 350px;
    height: 2.5rem;
    font-size: 1.5rem;
  }
}

.vertical-separator {
  width: 1px;
  background-color: black;
  height: 20px;
}

// CUSTOMER
.flex-end {
  display: flex;
  justify-content: flex-end;
  // max-width: 1270px;
  margin: auto auto 1rem auto;
  gap: 1rem;
}

.row {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.max-width-100 {
  max-width: 100ch;
  margin: auto;
}

.span-info {
  padding: 0.5rem;
  background-color: $white-blue;
  color: #0042eb50;
  border-radius: 4px;
  border: none;
  display: inline-block;
  width: calc(100ch / 2 - 1rem);
}

.input-info {
  padding: 0.5rem;
  box-sizing: border-box;
  border: none;
  border-radius: 4px;
  background-color: $white-blue;
  color: $blue-active;
  width: 50ch;
  font-size: 1rem;
  font-family: "League Spartan", sans-serif;
}

.customer-flatcardav3 {
  max-width: 1235px;
}

// CREATE CUSTOMER
.input-customer {
  padding: 0.5rem;
  background-color: $white-blue;
  color: $blue-active;
  border-radius: 4px;
  border: none;
  display: inline-block;
  box-sizing: border-box;
  width: calc(100ch / 2 - 1rem);
  font-size: 1rem;
  font-family: "League Spartan", sans-serif;
  &::placeholder {
    color: $blue;
  }
}

.dropzone-customer {
  width: calc(100ch / 2 - 1rem);
}

.thumbsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 1rem;
  .thumb {
    display: inline-flex;
    border-radius: 4px;
    border: 1px solid $blue;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    padding: 0.25rem;
    box-sizing: border-box;
    // width: 200px;
    // height: 75px;
    .thumbInner {
      display: flex;
      min-width: 0;
      overflow: hidden;
      .thumbInner-img {
        display: block;
        width: auto;
        height: 100%;
      }
    }
  }
}

.row-btn {
  display: flex;
  gap: 2rem;
  justify-content: center;
}

// CUSTOMERS
.search-bar {
  padding: 0.5rem 2rem 0.5rem 1rem;
  width: 300px;
  background: url(../../public/assets/loupe.svg) $white-blue no-repeat right;
  border: none;
  border-radius: 5px;
  color: $blue-active;
  &::placeholder {
    color: $blue-active;
  }
}

@media (min-width: 2500px) {
  .search-bar {
    width: 550px;
  }
}

.display-menu-customers {
  max-width: 85vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  .sortBy {
    width: 180px;
  }
}

// TICKET
.ticket-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 3px solid $white-blue;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 2rem;
}

.max-width {
  max-width: 150ch;
  margin: auto;
}

.ticket-title {
  font-size: 20px;
}

.chat-window {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.message-container {
  display: flex;
  flex-direction: column;
}

.right {
  text-align: right;
}

.left-margin {
  margin-left: 1rem;
}

.customer-user {
  padding: 2rem;
  background-color: $white-blue;
  max-width: 80ch;
  min-width: 80ch;
  border-radius: 5px;
  align-self: flex-end;
}

.hc-user {
  padding: 2rem;
  border: 3px solid $white-blue;
  max-width: 80ch;
  min-width: 80ch;
  border-radius: 5px;
  align-self: flex-start;
}

.respond-input {
  padding: 1rem 1rem;
  border: 3px solid $white-blue;
  border-radius: 5px;
  &::placeholder {
    color: $blue-active;
  }
}

.btn-right {
  align-self: flex-end;
}

.chat-composer {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 3rem;
}

.ticket-content {
  min-width: 90%;
  display: flex;
  flex-direction: column;
  resize: none;
  border: 2px solid $white-blue;
  border-radius: 8px;
  padding: 20px;
}

.ticket-status {
  min-width: 20ch;
}

.tstatus-1 {
  background-color: $inactive;
}

.tstatus-2 {
  background-color: $inactive;
}

.tstatus-3 {
  background-color: $inactive;
}

.tstatus-4 {
  background-color: $active;
}

.tstatus-5 {
  background-color: $active;
}

.tstatus-6 {
  background-color: $active;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: $white-blue;
  color: $blue-active;
  padding: 5px;
  border-radius: 6px;
  position: absolute;
}

.tooltiptext {
  font-size: 0.75rem;
  text-align: start;
  min-width: 50ch;
  border: 1px black solid;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

// .ticket-number {
//   font-size: 1.25rem;
// }

// ANTIVIRUS
.input-content {
  width: 1015px;
  height: 4rem;
  font-family: "League Spartan", sans-serif;
  font-size: 1rem;
  color: $blue-active;
  background-color: $white-blue;
  border-radius: 5px;
}

.antivirus-flatcardav4 {
  max-width: 1235px;
}

// USERS
.input-user {
  padding: 0.5rem;
  box-sizing: border-box;
  border: none;
  border-radius: 4px;
  background-color: $white-blue;
  color: $blue-active;
  // font-size: 1rem;
  font-family: "League Spartan", sans-serif;
  text-align: center;
  min-width: 100%;
}

.select-user {
  padding: 0.5rem;
  box-sizing: border-box;
  border: none;
  border-radius: 4px;
  background-color: $white-blue;
  color: $blue-active;
  font-size: 1rem;
  font-family: "League Spartan", sans-serif;
}

// CREATE USER
.width-50ch {
  width: calc(100ch / 2 - 1rem);
}

// STATISTIQUES
.border {
  border: 5px solid $white-blue;
  border-radius: 20px;
  padding: 3rem;
}

.btn-stats {
  background-color: $white;
  padding: 0.5rem 1rem;
  border: 3px solid $white-blue;
  border-radius: 5px;
  width: 100px;
  &:hover {
    border-color: $blue-active;
    color: $blue-active;
  }
  &.active {
    background-color: $blue-active;
    color: $white;
    border-color: $blue-active;
  }
}

.btn-container {
  display: flex;
  gap: 0.5rem;
}

.input-date {
  padding: 0.5rem;
  border: 3px solid $white-blue;
  border-radius: 5px;
  outline: none;
}

// ADMIN
.accordion-admin-game {
  box-sizing: border-box;
  min-width: 80vw;
}

.display-menu-game {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
}

// COMMUNICATION
.communication-flatcardcom3 {
  max-width: 80vw;
}

.dropzone,
.dropzone-customer {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: $white-blue;
  outline: none;
}

.dropzone {
  height: 450px;
}

// GAMES
.games-flatcardgame4 {
  max-width: 80vw;
}

.accordion-games {
  margin-left: auto;
  margin-right: auto;
  width: 80vw;
  margin-bottom: 1rem;
  border: 5px solid $white-blue;
  border-radius: 20px;
  padding: 1rem 6rem;
  box-sizing: border-box;
  h3 {
    text-transform: uppercase;
  }
}

// SETTINGS
.calendar-container {
  display: flex;
  border: 1px solid #ccc;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.hour-column {
  position: relative;
  border-right: 1px solid #ccc;
  padding: 10px;
  flex: 0 0 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.day-column {
  position: relative;
  padding: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #ccc;
}

.day-name,
.hour-name {
  text-align: center;
  font-weight: bold;
  height: 30px;
}

.event {
  background-color: #f2f2f2;
  border: 1px solid #ddd;
  padding: 5px;
  position: absolute;
  border-radius: 5px;
  box-sizing: border-box;
  width: calc(100% - 20px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.event-relative {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete-btn-event {
  position: absolute;
  top: -2px;
  right: 5px;
}

.select-menu-event {
  padding: 7px 10px;
  border: 2px solid $white-blue;
  border-radius: 4px;
  background-color: white;
  width: 250px;
}

.warning-message {
  font-weight: bold;
  color: red;
  font-size: 18px;
}
