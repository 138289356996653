.basic_button {
  &:hover {
    cursor: pointer;
  }
}

.basic_link {
  &:hover {
    cursor: pointer;
  }
}

.true-modal {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.basic_input {
  padding: 0.5rem;
  box-sizing: border-box;
  border: none;
  border-radius: 4px;
  background-color: $white-blue;
  color: $blue-active;
  &::placeholder {
    color: $blue-active;
  }
}

.button_custom {
  background-color: $blue;
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 20px;
  padding: 5px 15px;
  min-width: 150px;
  height: 30px;

  &:hover {
    cursor: pointer;
    background-color: $blue-active;
  }
}

.header {
  width: calc(100vw - $menu_expand_width);
  height: $header_height;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 5rem;
  .header_avatar {
    display: flex;
    align-items: center;
    gap: 1rem;
    position: absolute;
    right: 30px;
    img:hover {
      cursor: pointer;
    }
  }
  .bold {
    font-weight: bold;
  }
  
}

.accountLogout, .alert-menu {
  z-index: +999999999;
  background: $white-blue;
  position: absolute;
  border: 1px solid black;
  border-radius: 5px;
  top: 100px;
  right: 30px;
  padding: 20px;
}

@media (min-width: 2500px) {
  .accountLogout, .alert-menu {
    top: 200px
  }
}

.card_scanisette {
  position: relative;
  width: 390px;
  height: 500px;
  border: 5px solid $white-blue;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;
  margin-bottom: 1rem;
  &:hover {
    box-shadow: 5px 5px 50px 5px #03ebfc33;
  }
  hr {
    width: 390px;
    border: 1px solid $white-blue;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  .card_icon {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin-top: 1rem;
    img {
      translate: -5px -5px;
      width: 60px;
      height: 60px;
    }
  }
  &.active {
    .card_icon {
      background-color: $active;
    }
  }
  &.inactive {
    .card_icon {
      background-color: $inactive;
    }
  }
  &.almost-inactive {
    .card_icon {
      background-color: $almost-inactive;
    }
  }
  .card_info {
    margin-top: 1rem;
    span {
      font-weight: bold;
      border-radius: 4px;
      padding: 4px 8px 2px 8px;
      &.active {
        background-color: $active;
      }
      &.inactive {
        background-color: $inactive;
      }
      &.almost-inactive {
        background-color: $almost-inactive;
      }
    }
    .info {
      color: $blue-active;
    }
  }
  .container__more-details {
    position: absolute;
    background-color: $white-blue;
    width: 100%;
    bottom: 0px;
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    color: $blue-active;
    &:hover {
      color: white;
      background-color: $blue-active;
      cursor: pointer;
    }
    .more-details {
      font-weight: bold;
    }
  }
}

@media (min-width: 2500px) {
  .card_scanisette {
    width: 585px;
    height: 750px;
    .card_icon {
      border-radius: 50%;
      width: 75px;
      height: 75px;
      margin-top: 1rem;
      img {
        translate: -7.5px -7.5px;
        width: 90px;
        height: 90px;
      }
    }
  }
}

@media (max-width: 1279px) {
  .card_scanisette {
    width: 350px;
    height: 460px;
    hr {
      width: 350px
    }
  }
}

.flatCard {
  display: grid;
  align-items: center;
  gap: 2vw;
  border: 5px solid $white-blue;
  border-radius: 20px;
  padding: 1vw 2vw;
  // max-width: 1235px;
  // min-width: 1170px;
  margin-bottom: 1rem;
  box-sizing: border-box;
  min-height: 130px;
  margin-left: auto;
  margin-right: auto;
  &:hover {
    box-shadow: 5px 5px 50px 5px #03ebfc33;
  }
  .card_icon {
    img {
      object-fit: cover;
      max-width: 100%;
      max-height: 80px;
    }
    .icon {
      background-color: $white-blue;
      border-radius: 50%;
      width: 75px;
      height: 75px;
    }
  }
  .status {
    font-weight: bold;
    margin-bottom: 0.5rem;
    // &:before {
    //   content: "✓";
    //   background-color: green;
    // }
  }
  .flat-card-name {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  .begin-end {
    display: flex;
    justify-content: space-between;
  }
  .container-switch {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}
.grid-3 {
  grid-template-columns: 2vw 8vw 8vw;
}

.grid-4 {
  grid-template-columns: repeat(4, 1fr);
}

.grid-4-custom {
  grid-template-columns: 0.5fr 1fr 1fr 0.5fr;
}

.grid-4-content {
  grid-template-columns: 11vw 30vw 11vw 11vw;
}

.grid-5-content {
  grid-template-columns: 11vw 30vw 11vw 11vw 11vw;
}

.grid-6-content {
  grid-template-columns: 11vw 30vw 11vw 5vw 5vw 5vw;
}

.grid-4-game {
  grid-template-columns: 7vw 35vw 21vw 7vw;
}

.grid-4-flatcardav4-accordion {
  grid-template-columns: repeat(4, 190px);
}

.grid-6 {
  grid-template-columns: repeat(6, 12vw);
  // min-width: 1200px;
}

.grid-6-custom {
  grid-template-columns: repeat(6, 160px);
  max-width: 1200px;
}

.grid-5-custom {
  grid-template-columns: repeat(5, 150px);
}

.grid-5 {
  grid-template-columns: repeat(5, 12vw);
}

.grid-5-games {
  grid-template-columns: 3vw 7vw 35vw 15vw 7vw;
}

.grid-7-custom {
  grid-template-columns: 2vw 5vw 15vw repeat(3, 12vw) 5vw;
}

.text-center {
  text-align: center;
}

.bold {
  font-weight: bold;
}

.blue {
  color: $blue-active;
}

.card-column {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.more {
  color: $blue-active;
  font-size: 3rem;
  cursor: pointer;
  justify-self: center;
}
.flex {
  display: flex;
  align-items: flex-end;
  gap: 0.5rem;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

// hr {
//   max-width: 1235px;
//   border: 2px solid $white-blue;
//   margin-top: 2rem;
//   margin-bottom: 2rem;
// }

.ongoing {
  font-weight: bold;
  border-radius: 4px;
  padding: 4px 8px 2px 8px;
  background-color: $inactive;
}

.completed {
  font-weight: bold;
  border-radius: 4px;
  padding: 4px 8px 2px 8px;
  background-color: $active;
}

.select-menu {
  padding: 7px 10px;
  border: 2px solid $white-blue;
  border-radius: 4px;
  background-color: white;
}

.edit {
  text-decoration: underline;
  color: $blue-active;
  cursor: pointer;
}

span {
  &.active {
    font-weight: bold;
    border-radius: 4px;
    padding: 4px 8px 2px 8px;
    background-color: $active;
  }
}

span {
  &.inactive {
    font-weight: bold;
    border-radius: 4px;
    padding: 4px 8px 2px 8px;
    background-color: $inactive;
  }
}

.hpx20 {
  height: 20px;
}

.max-80 {
  max-width: 80vw;
  margin-left: auto;
  margin-right: auto;
}

.max-85 {
  max-width: 85vw;
}

.max-70 {
  max-width: 70vw;
  margin-left: auto;
  margin-right: auto;
}

.max-75 {
  max-width: 75vw;
  margin-left: auto;
  margin-right: auto;
}

.justify-content-center {
  justify-content: center;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
  margin: auto auto;
}

.truncated {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.av-content {
  margin-left: auto;
  margin-right: auto;
  width: 64vw;
  margin-bottom: 1rem;
  border: 5px solid $white-blue;
  border-radius: 20px;
  padding: 1rem 6rem;
  box-sizing: border-box;
  h3 {
    text-transform: uppercase;
  }
}

.flatCard_scanisette {
  // min-width: 1270px;
  // width: calc((390px + 5px * 2) * 3 + 1rem * 2 - 5px * 2);
  border: 5px solid $white-blue;
  border-radius: 20px;
  display: grid;
  grid-template-columns: repeat(6, 11vw);
  align-items: center;
  gap: 2vw;
  margin-bottom: 1rem;
  margin-right: 4rem;
  margin-left: 4rem;
  padding: 1.5rem 0px;
  max-width: 85vw;
  &:hover {
    box-shadow: 5px 5px 50px 5px #03ebfc33;
  }
  .card_icon {
    justify-self: center;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    img {
      width: 105px;
      translate: -17.5px -17.5px;
    }
  }
  &.active {
    .card_icon {
      background-color: $active;
    }
  }
  &.inactive {
    .card_icon {
      background-color: $inactive;
    }
  }
  &.almost-inactive {
    .card_icon {
      background-color: $almost-inactive;
    }
  }
  .card_info {
    span {
      font-weight: bold;
      display: inline-block;
      border-radius: 4px;
      padding: 4px 8px 2px 8px;
      margin-bottom: 0.5rem;
      &.active {
        background-color: $active;
      }
      &.inactive {
        background-color: $inactive;
      }
      &.almost-inactive {
        background-color: $almost-inactive;
      }
    }
  }
  .info {
    color: $blue-active;
    font-weight: bold;
  }
}

.flatCard_scan {
  display: flex;
  flex-direction: row;
  align-items: center;
  .card_icon {
    img {
      width: 105px;
    }
  }
}

.flatCardAV2 {
  display: grid;
  grid-template-columns: repeat(4, 1fr) 200px;
  max-width: 70vw;
  align-items: center;
  border: 5px solid $white-blue;
  border-radius: 20px;
  padding: 1rem 2rem;
  height: 80px;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  &:hover {
    box-shadow: 5px 5px 50px 5px #03ebfc33;
  }
  .status {
    font-weight: bold;
  }
  .free {
    font-weight: bold;
    color: $blue-active;
  }
  .card_icon {
    img {
      object-fit: cover;
      max-width: 100%;
      max-height: 80px;
      justify-self: center;
    }
  }
  // img {
  //   justify-self: center;
  // }
}

.btn-order {
  border-radius: 20px;
  background-color: #487cff;
  border: none;
  padding: 0.5rem 1.5rem;
  color: $white;
  font-weight: bold;
  justify-self: center;
  &:hover {
    background-color: $blue-active;
  }
}

@media (min-width: 2500px) {
  .btn-order {
    font-size: 2rem;
    border-radius: 40px;
  }
}

.basic_button.btn-order.disabled {
  background-color: rgba(204, 204, 204, 0.3);
  &:hover {
    cursor: not-allowed;
  }
}

.accordion-content {
  margin-left: auto;
  margin-right: auto;
  max-width: 62vw;
  margin-bottom: 1rem;
  border: 5px solid $white-blue;
  border-radius: 20px;
  padding: 1rem 6rem;
  h3 {
    text-transform: uppercase;
  }
}

// TAB NAVIGATION
.nav-items {
  border-bottom: 2px solid $white-blue;
  margin-bottom: 4rem;
  .nav-item {
    display: inline-block;
    list-style: none;
    margin-bottom: -2px;
    padding: 1.5rem 2rem;
    font-weight: bold;
    &.active {
      background-color: white;
      border: solid $white-blue;
      border-width: 2px 2px 0 2px;
      color: $blue-active;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  }
}
// END TAB NAVIGATION

// TITLE
.title {
  margin: 2rem 0 3rem 2rem;
  text-transform: uppercase;
}
// END TITLE

.image_upload {
  max-width: 400px;
  max-height: 500px;
  object-fit: contain;
  border: solid 1px black;
}

.list_virus {
  width: 80vw;
  min-height: 50px;
  margin-left: auto;
  margin-right: auto;
  .scan_row {
    background-color: #edf2ff;
    width: 75vw;
    height: 50px;
    border-radius: 10px;
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    padding-left: 10px;
    padding-right: 10px;

    .tag_scan {
      background-color: #5f8bfa;
      padding: 6px 10px;
      border-radius: 5px;;
      color: #fff;
      font-weight: 600;
    }
  }

  .detail_scan {
    height: fit-content;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    width: 80%;
    margin-left: 10%;

    .virus_detail {
      border: solid 1px black;
      height: 40px;
      width: 100%;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // font-size: 14px;
      min-height: 50px;

      .tag_scan {
        background-color: #5f8bfa;
        padding: 6px 10px;
        border-radius: 5px;;
        color: #fff;
        font-weight: 600;
      }
    }
  }
}

.pagination {
  width: 30%;
  margin-left: 35%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  span {
    color: #5f8bfa;
    font-weight: 600;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

}

.flex-column {
  flex-direction: column;
}

.justify-between
 {
  justify-content: space-between;
 }

 .b_danger {
  background-color: $inactive !important;
 }

 .b_ok {
  background-color: green !important;
 }

 .b_warning {
  background-color: $almost-inactive !important;
 }

 summary {
  // display: flex;
  position: relative;
  cursor: pointer;
}

details {
  cursor: pointer;
}
details summary::-webkit-details-marker {
  display:none;
}
details[open] > summary:before {
  transform: rotate(90deg);
}
summary:before {
  content: '';
  border-width: .4rem;
  border-style: solid;
  border-color: transparent transparent transparent #0042eb;
  position: absolute;
  top: 1.2rem;
  left: 1rem;
  transform: rotate(0);
  transform-origin: .2rem 50%;
  transition: .25s transform ease;
}