.d-flex {
  display: flex;
}

.column {
  flex-direction: column;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-end {
  justify-content: flex-end;
}

.uppercase {
  text-transform: uppercase;
}

.bold {
  font-weight: bold;
}

.hostname {
  font-weight: 200 !important;
  font-size: 15px;
}

@media (min-width: 2500px) {
  .hostname {
    font-size: 30px;
  }
}

.font-size-sm {
 font-size: 14px;
 font-weight: normal !important;
}

@media (min-width: 2500px) {
  .font-size-sm {
    font-size: 28px;
  }
}

.w35 {
  width: 35%;
}

.w85 {
  width: 85%;
}

.w100 {
  width: 100%;
}

.h100 {
  height: 100%;
}

.full-height {
  height: calc(100vh - $header_height);
}

.f-column {
  flex-direction: column;
}

.flex-1 {
  flex: 1;
}

.pointer {
  cursor: pointer;
}

.overflow-y-scroll-hidden {
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.overflow-auto {
  overflow-y: auto;
}

.position-fixed {
  position: fixed;  
}

.position-relative {
  position: relative;
}

.position-relative {
  position: absolute;
}

.object-contain {
  object-fit: contain;
}

.opacity {
  opacity: 0.6;
}

.h-120px {
  height: 120px;
}

.error {
  color: $inactive;
}

.success {
  color: $active;
}

.b-error {
  background-color: $inactive !important;
}

.fs24 {
  font-size: 24px;
}

.z-1000 {
  z-index: 1000;
}
.margin-auto {
  margin: auto;
}

.p-1 {
  padding: 5px;
}

.p-2 {
  padding: 10px;
}

.p-3 {
  padding: 15px;
}

.p-4 {
  padding: 20px;
}

.p-5 {
  padding: 25px;
}

.p-6 {
  padding: 30px;
}

.p-7 {
  padding: 35px;
}

.p-8 {
  padding: 40px;
}

.p-9 {
  padding: 45px;
}

.p-10 {
  padding: 50px;
}

.p-11 {
  padding: 55px;
}

.p-12 {
  padding: 60px;
}

.pl-1 {
  padding-left: 5px;
}

.pl-2 {
  padding-left: 10px;
}

.pl-3 {
  padding-left: 15px;
}

.pl-4 {
  padding-left: 20px;
}

.pl-5 {
  padding-left: 25px;
}

.pl-6 {
  padding-left: 30px;
}

.pl-7 {
  padding-left: 35px;
}

.pl-8 {
  padding-left: 40px;
}

.pl-9 {
  padding-left: 45px;
}

.pl-10 {
  padding-left: 50px;
}

.pl-11 {
  padding-left: 55px;
}

.pl-12 {
  padding-left: 60px;
}

.pr-1 {
  padding-right: 5px;
}

.pr-2 {
  padding-right: 10px;
}

.pr-3 {
  padding-right: 15px;
}

.pr-4 {
  padding-right: 20px;
}

.pr-5 {
  padding-right: 25px;
}

.pr-6 {
  padding-right: 30px;
}

.pr-7 {
  padding-right: 35px;
}

.pr-8 {
  padding-right: 40px;
}

.pr-9 {
  padding-right: 45px;
}

.pr-10 {
  padding-right: 50px;
}

.pr-11 {
  padding-right: 55px;
}

.pr-12 {
  padding-right: 60px;
}

.pb-1 {
  padding-bottom: 5px;
}

.pb-2 {
  padding-bottom: 10px;
}

.pb-3 {
  padding-bottom: 15px;
}

.pb-4 {
  padding-bottom: 20px;
}

.pb-5 {
  padding-bottom: 25px;
}

.pb-6 {
  padding-bottom: 30px;
}

.pb-7 {
  padding-bottom: 35px;
}

.pb-8 {
  padding-bottom: 40px;
}

.pb-9 {
  padding-bottom: 45px;
}

.pb-10 {
  padding-bottom: 50px;
}

.pb-11 {
  padding-bottom: 55px;
}

.pb-12 {
  padding-bottom: 60px;
}

.pt-1 {
  padding-top: 5px;
}

.pt-2 {
  padding-top: 10px;
}

.pt-3 {
  padding-top: 15px;
}

.pt-4 {
  padding-top: 20px;
}

.pt-5 {
  padding-top: 25px;
}

.pt-6 {
  padding-top: 30px;
}

.pt-7 {
  padding-top: 35px;
}

.pt-8 {
  padding-top: 40px;
}

.pt-9 {
  padding-top: 45px;
}

.pt-10 {
  padding-top: 50px;
}

.pt-11 {
  padding-top: 55px;
}

.pt-12 {
  padding-top: 60px;
}

.mt-1 {
  margin-top: 5px;
}

.mt-2 {
  margin-top: 10px;
}

.mt-3 {
  margin-top: 15px;
}

.mt-4 {
  margin-top: 20px;
}

.mt-5 {
  margin-top: 25px;
}

.mt-6 {
  margin-top: 30px;
}

.mt-7 {
  margin-top: 35px;
}

.mt-8 {
  margin-top: 40px;
}

.mt-9 {
  margin-top: 45px;
}

.mt-10 {
  margin-top: 50px;
}

.mt-11 {
  margin-top: 55px;
}

.mt-12 {
  margin-top: 60px;
}

.mb-1 {
  margin-bottom: 5px;
}

.mb-2 {
  margin-bottom: 10px;
}

.mb-3 {
  margin-bottom: 15px;
}

.mb-4 {
  margin-bottom: 20px;
}

.mb-5 {
  margin-bottom: 25px;
}

.mb-6 {
  margin-bottom: 30px;
}

.mb-7 {
  margin-bottom: 35px;
}

.mb-8 {
  margin-bottom: 40px;
}

.mb-9 {
  margin-bottom: 45px;
}

.mb-10 {
  margin-bottom: 50px;
}

.mb-11 {
  margin-bottom: 55px;
}

.mb-12 {
  margin-bottom: 60px;
}

.ml-1 {
  margin-left: 5px;
}

.ml-2 {
  margin-left: 10px;
}

.ml-3 {
  margin-left: 15px;
}

.ml-4 {
  margin-left: 20px;
}

.ml-5 {
  margin-left: 25px;
}

.ml-6 {
  margin-left: 30px;
}

.ml-7 {
  margin-left: 35px;
}

.ml-8 {
  margin-left: 40px;
}

.ml-9 {
  margin-left: 45px;
}

.ml-10 {
  margin-left: 50px;
}

.ml-11 {
  margin-left: 55px;
}

.ml-12 {
  margin-left: 60px;
}

.mr-1 {
  margin-right: 5px;
}

.mr-2 {
  margin-right: 10px;
}

.mr-3 {
  margin-right: 15px;
}

.mr-4 {
  margin-right: 20px;
}

.mr-5 {
  margin-right: 25px;
}

.mr-6 {
  margin-right: 30px;
}

.mr-7 {
  margin-right: 35px;
}

.mr-8 {
  margin-right: 40px;
}

.mr-9 {
  margin-right: 45px;
}

.mr-10 {
  margin-right: 50px;
}

.mr-11 {
  margin-right: 55px;
}

.mr-12 {
  margin-right: 60px;
}

.g-1 {
  gap: 5px;
}

.g-2 {
  gap: 10px;
}

.g-3 {
  gap: 15px;
}

.g-4 {
  gap: 20px;
}

.g-5 {
  gap: 25px;
}

.g-6 {
  gap: 30px;
}

.g-7 {
  gap: 35px;
}

.g-8 {
  gap: 40px;
}

.g-9 {
  gap: 45px;
}

.g-10 {
  gap: 50px;
}

.g-11 {
  gap: 55px;
}

.g-12 {
  gap: 60px;
}
