.large-radius {
  border-radius: 20px;
}

.small-radius {
  border-radius: 4px;
}

.rounded {
  border-radius: 50%;
}

.box-shadow-hover {
  &:hover {
    z-index: 1;
  box-shadow: 5px 5px 50px 5px $boxShadow;
  -webkit-box-shadow: 5px 5px 50px 5px $boxShadow;
  -moz-box-shadow: 5px 5px 50px 5px $boxShadow;
  }
  
}

.box-border {
  box-sizing: border-box;
}

.translate-15px {
  translate: -15px -15px;
}

.pointer {
  cursor: pointer;
}

// BORDER
.border-lg {
  border: solid 5px;
}

.border-md {
  border: solid 3px;
}

.b-border-white-blue {
  border-color: $white-blue;
}