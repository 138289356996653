@import "./styles/Page.styles.scss";
@import "./styles/Structure.styles.scss";
@import "./styles/Generic.styles.scss";
@import "./styles/Variables.styles.scss";
@import "./styles/Devices.styles.scss";
@import "./styles/Typography.styles.scss";
@import "./styles/Size.styles.scss";
@import "./styles/Grid.styles.scss";
@import "./styles/Utils.styles.scss";

@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  margin: 0;
  font-family: "League Spartan", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
