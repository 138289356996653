@import "../styles/Variables.styles.scss";

.switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 54px;
  height: 28px;
  background: #ccc;
  border-radius: 54px;
  position: relative;
  transition: background-color 0.2s;
}

.switch-label .switch-button {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  transition: 0.2s;
  background: $white-blue;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.switch-checkbox:checked + .switch-label .switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.switch-label:active .switch-button {
  width: 25px;
}

@media (max-width: 1279px) {
  .switch-checkbox {
    visibility: visible;
    height: inherit;
    width: inherit;
  }
  .switch-label {
    visibility: hidden;
  }
  .switch-label .switch-button {
    visibility: hidden;
  }
}