@import "../styles/Variables.styles.scss";

.modal-log {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  overflow-y: auto;
}

.modal-log-content {
  min-width: 500px;
  max-width: 90vw;
  background-color: $white;
  max-height: 90vh;
}

.modal-log-header,
.modal-log-footer,
.modal-log-body {
  padding: 1rem;
}
