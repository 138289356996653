@mixin attr-x($attr, $attr-count: 10, $attr-steps: 10, $unit: '%', $name: "exemple") {
  $attr-list: null;
  @for $i from 1 through $attr-count {
      $attr-value: $attr-steps * $i;

      .#{$name}#{$attr-value} {
          #{$attr}: #{$attr-value}#{$unit};
      }

      $attr-list: append($attr-list, unquote(".#{$attr}-#{$attr-value}"), comma);
  }

  #{$attr-list} {
  }
}

@include attr-x('width', 50, 2, '%', 'w');
@include attr-x('width', 150, 5, 'px', 'wpx');
@include attr-x('height', 50, 2, '%', 'h');
@include attr-x('height', 40, 5, 'px', 'hpx');
@include attr-x('max-height', 20, 5, '%', 'max-h');
@include attr-x('max-height', 20, 5, 'vh', 'max-hvh');
@include attr-x('max-width', 20, 5, 'vw', 'max-wvw');
@include attr-x('max-width', 100, 5, 'px', 'max-wpx');
@include attr-x('min-width', 100, 5, 'px', 'min-wpx');

@media (min-width: 2500px) {
  .max-wpx300 {
    max-width: 500px;
  }
  .hpx125 {
    height: 186px;
  }
  .hpx120 {
    height: 240px;
  }
  .hpx80 {
    height: 160px;
  }
  .hpx60 {
    height: 120px;
  }
  .hpx40 {
    height: 80px;
  }
  .hpx30 {
    height: 60px;
  }
  .hpx26 {
    height: 52px;
  }
  .wpx125 {
    width: 186px;
  }
  .wpx120 {
    width: 240px;
  }
  .wpx80 {
    width: 160px;
  }
  .wpx60 {
    width: 120px;
  }
  .wpx40 {
    width: 80px;
  }
  .wpx30 {
    width: 60px;
  }
  .wpx26 {
    width: 52px;
  }
}

@media (max-width: 1279px) {
  .hpx80 {
    height: 50px;
  }
}